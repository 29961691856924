define("ember-paper/components/paper-select/eps-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yP55e7z4",
    "block": "{\"symbols\":[\"@extra\",\"@placeholder\",\"@select\",\"&default\",\"@allowClear\"],\"statements\":[[7,\"md-select-value\",true],[11,\"class\",[29,[\"md-select-value \",[28,\"if\",[[23,0,[\"isPlaceholder\"]],\"md-select-placeholder\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"selected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item\"],[8],[14,4,[[23,3,[\"selected\"]],[23,3,[]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,5,[]],[28,\"not\",[[23,3,[\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-clear-btn\"],[12,\"role\",\"button\"],[3,\"on\",[\"mousedown\",[23,0,[\"clear\"]]]],[3,\"on\",[\"touchstart\",[23,0,[\"clear\"]]]],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-placeholder\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-placeholder\"],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"md-select-icon\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/eps-trigger/template.hbs"
    }
  });

  _exports.default = _default;
});