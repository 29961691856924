define("ember-cp-validations/utils/utils", ["exports", "ember-require-module", "ember-cp-validations/-private/ember-internals"], function (_exports, _emberRequireModule, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapString = unwrapString;
  _exports.unwrapProxy = unwrapProxy;
  _exports.isProxy = isProxy;
  _exports.isPromise = isPromise;
  _exports.isDsModel = isDsModel;
  _exports.isDSManyArray = isDSManyArray;
  _exports.isEmberObject = isEmberObject;
  _exports.isObject = isObject;
  _exports.isValidatable = isValidatable;
  _exports.getValidatableValue = getValidatableValue;
  _exports.mergeOptions = mergeOptions;
  Object.defineProperty(_exports, "getDependentKeys", {
    enumerable: true,
    get: function () {
      return _emberInternals.getDependentKeys;
    }
  });
  Object.defineProperty(_exports, "isDescriptor", {
    enumerable: true,
    get: function () {
      return _emberInternals.isDescriptor;
    }
  });
  const DS = (0, _emberRequireModule.default)('ember-data');
  const {
    canInvoke
  } = Ember;

  function unwrapString(s) {
    if (Ember.String.isHTMLSafe(s)) {
      return s.toString();
    }

    return s;
  }

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(Ember.get(o, 'content')) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }

  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }

  function isDsModel(o) {
    return !!(DS && o && o instanceof DS.Model);
  }

  function isDSManyArray(o) {
    return !!(DS && o && Ember.isArray(o) && (o instanceof DS.PromiseManyArray || o instanceof DS.ManyArray));
  }

  function isEmberObject(o) {
    return !!(o && o instanceof Ember.Object);
  }

  function isObject(o) {
    return Ember.typeOf(o) === 'object' || Ember.typeOf(o) === 'instance';
  }

  function isValidatable(value) {
    let v = unwrapProxy(value);
    return isDsModel(v) ? !Ember.get(v, 'isDeleted') : true;
  }

  function getValidatableValue(value) {
    if (!value) {
      return value;
    }

    if (isDSManyArray(value)) {
      return Ember.A(value.filter(v => isValidatable(v)));
    }

    return isValidatable(value) ? value : undefined;
  }

  function mergeOptions(...options) {
    let o = {};

    for (let i = options.length - 1; i >= 0; i--) {
      let _o = options[i];
      Ember.assign(o, isObject(_o) ? _o : {});
    }

    return o;
  }
});