define("ember-paper/templates/components/paper-toaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CphU2MZH",
    "block": "{\"symbols\":[\"toast\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[4,\"if\",[[24,[\"activeToast\",\"show\"]]],null,{\"statements\":[[4,\"paper-toast\",null,[[\"onClose\",\"position\",\"duration\",\"class\",\"parent\"],[[28,\"action\",[[23,0,[]],[24,[\"onClose\"]],[24,[\"activeToast\"]]],null],[24,[\"activeToast\",\"position\"]],[24,[\"activeToast\",\"duration\"]],[24,[\"activeToast\",\"toastClass\"]],[24,[\"parent\"]]]],{\"statements\":[[4,\"if\",[[24,[\"activeToast\",\"componentName\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[24,[\"activeToast\",\"componentName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"text\"]],\"expected `toast.text` to be a contextual component but found a string. Did you mean `(component toast.text)`? ('ember-paper/templates/components/paper-toaster.hbs' @ L12:C9) \"],null]],null,{\"statements\":[[1,[24,[\"activeToast\",\"text\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"activeToast\",\"action\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"primary\",\"accent\",\"warn\",\"onClick\"],[[24,[\"activeToast\",\"action\",\"primary\"]],[24,[\"activeToast\",\"action\",\"accent\"]],[24,[\"activeToast\",\"action\",\"warn\"]],[24,[\"activeToast\",\"action\",\"onClick\"]]]],{\"statements\":[[0,\"          \"],[1,[24,[\"activeToast\",\"action\",\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-toaster.hbs"
    }
  });

  _exports.default = _default;
});