define('ember-cpm/macros/if-null', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    return (0, _computed.default)(...arguments, (value, fallback) => {
      return value != null ? value : fallback || arguments[1];
    });
  };
});