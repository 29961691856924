define("ember-paper/validators/min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.min = min;
  _exports.default = void 0;

  function min(value, min) {
    return Ember.isEmpty(min) || Ember.isEmpty(value) || parseFloat(value) >= parseFloat(min);
  }

  var _default = {
    param: 'min',
    message: 'Must be at least %@.',
    validate: min
  };
  _exports.default = _default;
});