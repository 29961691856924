define("ember-paper/validators/max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.max = max;
  _exports.default = void 0;

  function max(value, max) {
    return Ember.isEmpty(max) || Ember.isEmpty(value) || parseFloat(value) <= parseFloat(max);
  }

  var _default = {
    param: 'max',
    message: 'Must be less than %@.',
    validate: max
  };
  _exports.default = _default;
});