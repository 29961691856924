define('ember-cpm/macros/fmt', ['exports', 'ember-cpm/utils', 'ember-macro-helpers/computed'], function (exports, _utils, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (...args) {
    let formatString = args.pop();

    return (0, _computed.default)(...args, (...values) => {
      for (let i in values) {
        let value = values[i];
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
      }

      return (0, _utils.fmt)(formatString, values);
    });
  };
});