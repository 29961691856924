define("ember-paper/utils/key-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TAB = _exports.DOWN_ARROW = _exports.RIGHT_ARROW = _exports.UP_ARROW = _exports.LEFT_ARROW = _exports.SPACE = _exports.ESCAPE = _exports.ENTER = void 0;
  const ENTER = 13;
  _exports.ENTER = ENTER;
  const ESCAPE = 27;
  _exports.ESCAPE = ESCAPE;
  const SPACE = 32;
  _exports.SPACE = SPACE;
  const LEFT_ARROW = 37;
  _exports.LEFT_ARROW = LEFT_ARROW;
  const UP_ARROW = 38;
  _exports.UP_ARROW = UP_ARROW;
  const RIGHT_ARROW = 39;
  _exports.RIGHT_ARROW = RIGHT_ARROW;
  const DOWN_ARROW = 40;
  _exports.DOWN_ARROW = DOWN_ARROW;
  const TAB = 9;
  _exports.TAB = TAB;
});