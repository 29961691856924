define("ember-paper/templates/components/paper-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s/ap778W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"paper-toast-inner\",null,[[\"swipe\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\"],[[24,[\"swipeAction\"]],[24,[\"swipeToClose\"]],[24,[\"onClose\"]],[24,[\"top\"]],[24,[\"left\"]],[24,[\"capsule\"]],[24,[\"class\"]]]],{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"text\"],[[28,\"component\",[\"paper-toast-text\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-toast.hbs"
    }
  });

  _exports.default = _default;
});