define("ember-paper/components/paper-autocomplete/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OUQS6IM1",
    "block": "{\"symbols\":[\"token\",\"&attrs\"],\"statements\":[[7,\"span\",false],[13,2],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"tokens\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isMatch\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"highlight\"],[8],[1,[23,1,[\"text\"]],false],[9]],\"parameters\":[]},{\"statements\":[[1,[23,1,[\"text\"]],false]],\"parameters\":[]}]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/highlight/template.hbs"
    }
  });

  _exports.default = _default;
});