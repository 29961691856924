define('ember-cpm/macros/all-equal', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeysUnsafe)((firstVal, ...values) => {
    for (let i = 0; i < values.length; i += 1) {
      if (values[i] !== firstVal) {
        return false;
      }
    }
    return true;
  });
});