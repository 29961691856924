define('ember-cpm/macros/first-present', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeys)((...values) => {
    for (let i in values) {
      let value = values[i];
      if (Ember.isPresent(value)) {
        return value;
      }
    }
  });
});