define("ember-paper/templates/components/paper-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hdM2wg/7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"-paper-underscore\",[[24,[\"iconClass\"]]],null],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-icon.hbs"
    }
  });

  _exports.default = _default;
});