define('ember-cpm/macros/among', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeysUnsafe)((source, ...values) => {
    for (let i = 0; i < values.length; i++) {
      if (values[i] === source) {
        return true;
      }
    }
    return false;
  });
});