define('ember-cpm/macros/join', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (...args) {
    var separator = args.pop();

    return (0, _computed.default)(...args, (...values) => {
      return values.join(separator);
    });
  };
});