define("ember-paper/components/paper-autocomplete/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nkI4mIAN",
    "block": "{\"symbols\":[\"opt\",\"index\",\"@options\",\"@select\",\"&attrs\",\"@groupIndex\",\"&default\"],\"statements\":[[5,\"vertical-collection\",[[12,\"class\",\"md-autocomplete-suggestions\"],[12,\"role\",\"listbox\"],[12,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[23,4,[\"uniqueId\"]]]]],[13,5],[3,\"did-insert\",[[23,0,[\"addHandlers\"]]]]],[[\"@items\",\"@tagName\",\"@containerSelector\",\"@estimateHeight\",\"@staticHeight\",\"@bufferSize\"],[[23,3,[]],\"ul\",\".md-autocomplete-suggestions-container\",48,true,3]],{\"statements\":[[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[\"ember-power-select-option md-autocomplete-suggestion \",[28,\"if\",[[28,\"eq\",[[23,1,[]],[23,4,[\"highlighted\"]]],null],\"selected\"],null]]]],[11,\"aria-selected\",[29,[[28,\"ember-power-select-is-selected\",[[23,1,[]],[23,4,[\"selected\"]]],null]]]],[11,\"aria-disabled\",[28,\"if\",[[23,1,[\"disabled\"]],\"true\"],null]],[11,\"aria-current\",[29,[[28,\"eq\",[[23,1,[]],[23,4,[\"highlighted\"]]],null]]]],[11,\"data-option-index\",[29,[[23,6,[]],[23,2,[]]]]],[10,\"role\",\"option\"],[11,\"tabindex\",[28,\"if\",[[23,1,[\"disabled\"]],\"-1\",\"0\"],null]],[8],[0,\"\\n    \"],[14,7,[[23,1,[]],[23,4,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/options/template.hbs"
    }
  });

  _exports.default = _default;
});