define("ember-paper/templates/components/paper-progress-circular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wmdbernG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",[29,[\"0 0 \",[22,\"diameter\"],\" \",[22,\"diameter\"]]]],[11,\"style\",[22,\"svgStyle\"]],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"fill\",\"none\"],[11,\"style\",[22,\"pathStyle\"]],[11,\"stroke-dasharray\",[22,\"strokeDasharray\"]],[11,\"d\",[22,\"d\"]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-progress-circular.hbs"
    }
  });

  _exports.default = _default;
});