define("ember-can/helpers/can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    can: Ember.inject.service(),
    ability: null,
    propertyName: null,

    compute([abilityString, model], properties) {
      let {
        abilityName,
        propertyName
      } = this.can.parse(abilityString);
      let ability = this.can.abilityFor(abilityName, model, properties);
      propertyName = ability.parseProperty(propertyName);

      this._removeAbilityObserver();

      this._addAbilityObserver(ability, propertyName);

      return Ember.get(ability, propertyName);
    },

    destroy() {
      this._removeAbilityObserver();

      return this._super(...arguments);
    },

    _addAbilityObserver(ability, propertyName) {
      Ember.setProperties(this, {
        ability,
        propertyName
      });
      Ember.addObserver(this, `ability.${propertyName}`, this, 'recompute');
    },

    _removeAbilityObserver() {
      Ember.removeObserver(this, `ability.${this.propertyName}`, this, 'recompute');
      this.ability && this.ability.destroy();
      Ember.setProperties(this, {
        ability: null,
        propertyName: null
      });
    }

  });

  _exports.default = _default;
});