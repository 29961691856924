define("ember-paper/templates/components/paper-speed-dial-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2X0s7sH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"action\"],[[28,\"component\",[\"paper-speed-dial-actions-action\"],[[\"speedDial\"],[[24,[\"speedDial\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-speed-dial-actions.hbs"
    }
  });

  _exports.default = _default;
});