define("ember-power-select/components/power-select/trigger", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Trigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_trigger.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Trigger extends Ember.Component {
    clear(e) {
      e.stopPropagation();
      this.select.actions.select(null);

      if (e.type === 'touchstart') {
        return false;
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "clear", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Trigger;
});