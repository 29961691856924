define("ember-paper/templates/components/paper-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMLeupBZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[1,[28,\"paper-backdrop\",null,[[\"locked-open\",\"opaque\",\"fixed\",\"class\",\"onClick\"],[[24,[\"isLockedOpen\"]],[24,[\"opaque\"]],[28,\"unless\",[[24,[\"parent\"]],true],null],\"md-dialog-backdrop\",[28,\"action\",[[23,0,[]],\"outsideClicked\"],null]]]],false],[0,\"\\n\"],[4,\"paper-dialog-container\",null,[[\"class\",\"outsideClicked\"],[[28,\"readonly\",[[24,[\"dialogContainerClass\"]]],null],[28,\"action\",[[23,0,[]],\"outsideClicked\"],null]]],{\"statements\":[[4,\"paper-dialog-inner\",null,[[\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\"],[[28,\"readonly\",[[24,[\"class\"]]],null],[24,[\"origin\"]],[24,[\"defaultedParent\"]],[24,[\"defaultedOpenFrom\"]],[24,[\"defaultedCloseTo\"]],[24,[\"fullscreen\"]],[24,[\"focusOnOpen\"]]]],{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-dialog.hbs"
    }
  });

  _exports.default = _default;
});