define("ember-power-select/components/power-select/search-message", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/search-message"], function (_exports, _component, _searchMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let SearchMessage = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_searchMessage.default), _dec(_class = _dec2(_class = class SearchMessage extends Ember.Component {}) || _class) || _class);
  _exports.default = SearchMessage;
});