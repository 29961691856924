define('ember-cli-mirage/utils/inflector', ['exports', 'ember-inflector', '@ember/string'], function (exports, _emberInflector, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.underscore = exports.dasherize = exports.camelize = exports.capitalize = exports.pluralize = exports.singularize = undefined;
  Object.defineProperty(exports, 'singularize', {
    enumerable: true,
    get: function () {
      return _emberInflector.singularize;
    }
  });
  Object.defineProperty(exports, 'pluralize', {
    enumerable: true,
    get: function () {
      return _emberInflector.pluralize;
    }
  });
  exports.capitalize = _string.capitalize;
  exports.camelize = _string.camelize;
  exports.dasherize = _string.dasherize;
  exports.underscore = _string.underscore;
});