define('ember-cpm/macros/safe-string', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmberString = Ember.String;

  /**
    Casts the value of the given dependent key to a Handlebars.SafeString
  
    @method safeString
    @for macros
    @param {String} Dependent key with the string to cast.
    @return {Ember.Handlebars.SafeString} The casted string.
  */
  exports.default = (0, _utils.resolveKeys)(value => {
    return value && new EmberString.htmlSafe(value);
  });
});