define("ember-paper/components/paper-select/ebd-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8IwUDL4m",
    "block": "{\"symbols\":[\"@dropdown\",\"@required\",\"@label\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"shouldShowLabel\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"for\",[29,[\"ember-basic-dropdown-trigger-\",[23,1,[\"uniqueId\"]]]]],[11,\"class\",[28,\"if\",[[23,2,[]],\"md-required\"],null]],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"basic-dropdown-trigger\",[[13,7],[12,\"id\",[29,[\"ember-basic-dropdown-trigger-\",[23,1,[\"uniqueId\"]]]]],[12,\"disabled\",[23,8,[]]],[12,\"required\",[23,2,[]]]],[[\"@htmlTag\",\"@eventType\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-select\",\"click\",[23,1,[]],[23,4,[]],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n  \"],[14,9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/ebd-trigger/template.hbs"
    }
  });

  _exports.default = _default;
});