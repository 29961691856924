define("ember-paper/templates/components/paper-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewvC7wjY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"md-slider-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-slider-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-track-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-track\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-track md-track-fill\"],[11,\"style\",[22,\"activeTrackStyle\"]],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-track-ticks\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-thumb-container\"],[11,\"style\",[22,\"thumbContainerStyle\"]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-thumb\"],[10,\"style\",\"touch-action: none\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-focus-thumb\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-focus-ring\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-sign\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"md-thumb-text\"],[8],[1,[22,\"value\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-disabled-thumb\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-slider.hbs"
    }
  });

  _exports.default = _default;
});