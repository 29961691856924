define("ember-paper/components/paper-select/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v/VMBGg5",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"md-option\",false],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-text\"],[8],[14,2],[9],[0,\"\\n  \"],[5,\"paper-ripple\",[],[[],[]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/option/template.hbs"
    }
  });

  _exports.default = _default;
});