define("ember-paper/components/paper-menu/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BlbZAHiE",
    "block": "{\"symbols\":[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"&default\"],\"statements\":[[5,\"basic-dropdown-trigger\",[[12,\"class\",\"md-menu\"],[12,\"tabindex\",\"-1\"],[13,5]],[[\"@htmlTag\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-menu\",[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n  \"],[14,6],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/trigger/template.hbs"
    }
  });

  _exports.default = _default;
});