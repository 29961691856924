define('ember-cpm/macros/mean', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeysUnsafe)((...values) => {
    var sum = 0;
    var count = 0;

    values.forEach(v => {
      let t = Ember.typeOf(v);
      switch (t) {
        case 'number':
          // Number case
          count += 1;
          sum += v;
          break;
        case 'array':
          // Array case
          sum += v.reduce((p, i) => p + i, 0); // sum of array
          count += v.length;
          break;
        case 'undefined':
        case 'null':
          break;
        default:
          var msg = `Unsupported value type: ${t}`;
          throw new TypeError(msg);
      }
    });
    return count > 0 ? sum / count : 0;
  });
});