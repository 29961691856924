define("ember-paper/templates/components/paper-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69WGcCDl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"md-switch-bar\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"md-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-bar\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-thumb-container\"],[11,\"style\",[22,\"thumbContainerStyle\"]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-thumb\"],[8],[0,\"\\n      \"],[5,\"paper-ripple\",[],[[\"@center\",\"@fitRipple\"],[true,true]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-label\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-label\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-switch.hbs"
    }
  });

  _exports.default = _default;
});