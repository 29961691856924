define('ember-cpm/macros/hash', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (...props) {
    return (0, _computed.default)(...props, (...values) => {
      let result = {};
      props.forEach((prop, i) => {
        result[prop] = values[i];
      });
      return result;
    });
  };
});