define("ember-paper/templates/components/paper-optgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZTbyJ2P/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-optgroup.hbs"
    }
  });

  _exports.default = _default;
});