define("ember-paper/components/paper-select/options/component", ["exports", "ember-power-select/components/power-select/options", "ember-paper/components/paper-select/options/template", "@ember-decorators/component"], function (_exports, _options, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaperSelectOptions = (_dec = (0, _component.tagName)('md-content'), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('select.uniqueId'), _dec(_class = _dec2(_class = (_class2 = class PaperSelectOptions extends _options.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attributeBindings", ['role:role', 'ariaControls:aria-controls']);

      _defineProperty(this, "role", 'listbox');
    }

    get ariaControls() {
      return `ember-power-select-trigger-${this.select.uniqueId}`;
    }

    init() {
      if (this.isGroup) {
        this.set('tagName', '');
        this.set('attributeBindings', undefined);
      }

      super.init(...arguments);
    }

    didInsertElement() {
      if (this.isGroup) {
        return;
      }

      this.addHandlers(this.element);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "ariaControls", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "ariaControls"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperSelectOptions;
  _exports.default = _default;
});