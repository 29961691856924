define('ember-cpm/macros/concat', ['exports', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (...args) {
    const arrayReduce = (...args) => {
      return args.reduce((prev, value) => {
        return prev.concat(value || []);
      }, []);
    };

    return (0, _computed.default)(...args.map(a => (0, _normalizeArrayKey.default)(a, [])), arrayReduce);
  };
});