define("ember-paper/components/paper-radio-base", ["exports", "ember-paper/templates/components/paper-radio-base", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-invoke-action"], function (_exports, _paperRadioBase, _focusableMixin, _colorMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperRadio
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   */
  var _default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, {
    layout: _paperRadioBase.default,
    tagName: 'md-radio-button',
    classNames: ['md-default-theme'],
    classNameBindings: ['checked:md-checked'],
    attributeBindings: ['role', 'ariaChecked:aria-checked', 'ariaLabel:aria-label'],
    tabindex: null,
    toggle: false,
    role: 'radio',

    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,

    // Lifecycle hooks
    init() {
      (true && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-radio}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));

      this._super(...arguments);
    },

    checked: Ember.computed('groupValue', 'value', function () {
      return this.get('groupValue') === this.get('value');
    }),
    ariaChecked: Ember.computed('checked', function () {
      return this.get('checked') ? 'true' : 'false';
    }),
    labelId: Ember.computed('elementId', function () {
      return `${this.elementId}-label`;
    }),

    click() {
      if (!this.get('disabled')) {
        if (this.get('toggle')) {
          (0, _emberInvokeAction.invokeAction)(this, 'onChange', this.get('checked') ? null : this.get('value'));
        } else {
          (0, _emberInvokeAction.invokeAction)(this, 'onChange', this.get('value'));
        }
      } // Prevent bubbling, if specified. If undefined, the event will bubble.


      return this.get('bubbles');
    }

  });

  _exports.default = _default;
});