define("ember-paper/components/paper-autocomplete/no-matches-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bV0sERAU",
    "block": "{\"symbols\":[\"@onCreate\",\"@noMatchesMessage\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"ember-power-select-options md-autocomplete-suggestions\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n  \"],[7,\"li\",false],[12,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message md-autocomplete-suggestion\"],[12,\"role\",\"option\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/no-matches-message/template.hbs"
    }
  });

  _exports.default = _default;
});