define("ember-paper/mixins/events-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EventsMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    touchStart(e) {
      return this.down(e);
    },

    mouseDown(e) {
      this.down(e);
    },

    touchEnd(e) {
      return this.up(e);
    },

    mouseUp(e) {
      return this.up(e);
    },

    touchCancel(e) {
      return this.up(e);
    },

    mouseLeave(e) {
      return this.up(e);
    },

    up() {},

    down() {},

    contextMenu() {},

    /*
     * Move events
     */
    mouseMove(e) {
      return this.move(e);
    },

    touchMove(e) {
      return this.move(e);
    },

    pointerMove(e) {
      return this.move(e);
    },

    move() {}

  });

  _exports.default = _default;
});