define("ember-paper/components/paper-menu/component", ["exports", "ember-paper/components/paper-menu/template", "ember-paper/utils/clamp", "@ember-decorators/component"], function (_exports, _template, _clamp, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function firstVisibleChild(node) {
    for (let i = 0; i < node.children.length; ++i) {
      if (window.getComputedStyle(node.children[i]).display !== 'none') {
        return node.children[i];
      }
    }
  }

  const MENU_EDGE_MARGIN = 8;
  let PaperMenu = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember.computed('position'), _dec6 = Ember.computed('offset'), _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperMenu extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "position", 'target');

      _defineProperty(this, "offset", '0 0');
    }

    close() {
      this.didAnimateScale = false;
    }

    open() {
      this.didAnimateScale = false;
    }

    // If attachment is a single item, duplicate it for our second value.
    // ie. 'target' -> 'target target'
    get positionMode() {
      let position = this.position || 'target';
      let [left, top] = position.split(' ').map(s => s.trim());
      top = top || left;
      return {
        left,
        top
      };
    }

    get offsets() {
      let offset = this.get('offset') || '0 0';
      let [left, top] = offset.split(' ').map(s => s.trim()).map(parseFloat);
      top = top || left;
      return {
        left,
        top
      };
    }

    calculatePosition(trigger, content) {
      let containerNode = content;
      let openMenuNode = content.firstElementChild;
      let openMenuNodeRect = openMenuNode.getBoundingClientRect();
      let boundryNode = document.body;
      let boundryNodeRect = boundryNode.getBoundingClientRect();
      let menuStyle = window.getComputedStyle(openMenuNode);
      let originNode = trigger.querySelector('.md-menu-origin') || trigger.querySelector('md-icon') || trigger;
      let originNodeRect = originNode.getBoundingClientRect();
      let bounds = {
        left: boundryNodeRect.left + MENU_EDGE_MARGIN,
        top: Math.max(boundryNodeRect.top, 0) + MENU_EDGE_MARGIN,
        bottom: Math.max(boundryNodeRect.bottom, Math.max(boundryNodeRect.top, 0) + boundryNodeRect.height) - MENU_EDGE_MARGIN,
        right: boundryNodeRect.right - MENU_EDGE_MARGIN
      };
      let alignTarget;
      let alignTargetRect = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
      let positionMode = this.positionMode;

      if (positionMode.top === 'target' || positionMode.left === 'target' || positionMode.left === 'target-right') {
        alignTarget = firstVisibleChild(openMenuNode);

        if (alignTarget) {
          // TODO: Allow centering on an arbitrary node, for now center on first menu-item's child
          alignTarget = alignTarget.firstElementChild || alignTarget;
          alignTarget = alignTarget.querySelector('md-icon') || alignTarget.querySelector('.md-menu-align-target') || alignTarget;
          alignTargetRect = alignTarget.getBoundingClientRect();
        }
      }

      let position = {};
      let transformOrigin = 'top ';

      switch (positionMode.top) {
        case 'target':
          position.top = originNodeRect.top - alignTarget.offsetTop;
          break;

        case 'cascade':
          position.top = originNodeRect.top - parseFloat(menuStyle.paddingTop) - originNode.style.top;
          break;

        case 'bottom':
          position.top = originNodeRect.top + originNodeRect.height;
          break;

        default:
          (true && !(false) && Ember.assert(`Invalid target mode '${positionMode.top}' specified for paper-menu on Y axis.`));
      }

      switch (positionMode.left) {
        case 'target':
          {
            position.left = originNodeRect.left - alignTarget.offsetLeft;
            transformOrigin += 'left';
            break;
          }

        case 'target-left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        case 'target-right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width + (openMenuNodeRect.right - alignTargetRect.right);
            transformOrigin += 'right';
            break;
          }

        case 'cascade':
          {
            let willFitRight = originNodeRect.right + openMenuNodeRect.width < bounds.right;
            position.left = willFitRight ? originNodeRect.right - originNode.style.left : originNodeRect.left - originNode.style.left - openMenuNodeRect.width;
            transformOrigin += willFitRight ? 'left' : 'right';
            break;
          }

        case 'right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width;
            transformOrigin += 'right';
            break;
          }

        case 'left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        default:
          {
            (true && !(false) && Ember.assert(`Invalid target mode '${positionMode.left}' specified for paper-menu on X axis.`));
          }
      } // sum offsets


      let offsets = this.offsets;
      position.top += offsets.top;
      position.left += offsets.left;
      (0, _clamp.default)(position, bounds, containerNode);
      let dropdownTop = Math.round(position.top);
      let dropdownLeft = Math.round(position.left);
      let scaleX = Math.round(100 * Math.min(originNodeRect.width / containerNode.offsetWidth, 1.0)) / 100;
      let scaleY = Math.round(100 * Math.min(originNodeRect.height / containerNode.offsetHeight, 1.0)) / 100;
      let style = {
        top: dropdownTop,
        left: dropdownLeft,
        // Animate a scale out if we aren't just repositioning
        transform: !this.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        'transform-origin': transformOrigin
      };
      /*
      dropdown.setProperties({
        transform: !dropdown.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        transformOrigin
      });
      */

      this.didAnimateScale = true;
      return {
        style,
        horizontalPosition: '',
        verticalPosition: ''
      };
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "close", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "positionMode", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "positionMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "offsets", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "offsets"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "calculatePosition", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "calculatePosition"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperMenu;
  _exports.default = _default;
});